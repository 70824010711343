import {NgxLoggerLevel} from 'ngx-logger';

export const environment = {
  production: false,
  flagsimagesDir: 'assets/images/flags/',
  apiBaseUrl: 'https://lsms-frontpage.staging.l-shop.team/api',
  imagesUrl: 'https://frontpage.staging.l-shop.team/images/',
  apiName: 'CMS-Frontpage (staging)',
  logLevel: NgxLoggerLevel.ERROR,
  serverLogLevel: NgxLoggerLevel.ERROR,
  disableConsoleLogging: false
};
